import React, { useEffect, useState } from "react";
import "./partner_calendar.css"
import { Calendar, Badge, List, HStack } from 'rsuite';
import { EventCelendarListFetch } from "../../connector/connectorV2";

function getTodoList(date, events) {
    function formatTime(number) {
        return number < 10 ? '0' + number : number;
    }
    if (!date) {
      return [];
    }
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getYear()
    let my_list = []
    for(let i = 0; i < events.length; ++i){
      
      const main_date = (events[i]?.event_datetime ? new Date(events[i]?.event_datetime) : null)
      if(main_date != null && main_date.getDate() == day && main_date.getMonth() == month && main_date.getYear() == year){
        my_list.push({time: `${formatTime(main_date.getHours())}:${formatTime(main_date.getMinutes())}`, title:events[i].title})
      }
    }
    return my_list
  }
  
  function renderCell(date, events) {
    const list = getTodoList(date, events);
  
    if (list.length) {
      return <Badge className="calendar-todo-item-badge" />;
    }
  
    return null;
  }
const TodoList = ({ date, events }) => {
    const list = getTodoList(date, events);
  
    if (!list.length) {
      return <div className="special_no_mercy">На этот день планов нет</div>;
    }
  
    return (
      <List style={{ flex: 1 }} bordered>
        {list.map(item => (
          <List.Item key={item.time} index={item.time}>
            <div>{item.time}</div>
            <div>{item.title}</div>
          </List.Item>
        ))}
      </List>
    );
  };

export default function PartnerCalendar(props){
  const [selectedDate, setSelectedDate] = useState(null);
  const [events, set_events] = useState([])
  useEffect(() => {
    EventCelendarListFetch({}).then(res => {
      set_events(res.data)
    })
  }, [])
  const handleSelect = date => {
    setSelectedDate(date);
  };
    return (
        <div className="bnv1">
            <div className="title">Календарь мероприятий</div>
            <div className="hstack_fnck">
                <Calendar compact isoWeek renderCell={(date) => {return renderCell(date, events)}} onSelect={handleSelect} className="partn_calendar" />
                <TodoList date={selectedDate} events={events} />
            </div>
        </div>
    )
}