import React, { useEffect, useState } from "react";
import OfficeMap from "../office_map/office_map";
import { OfficesListFetch } from "../../connector/connectorV2";


export default function AdministrationContacts({}){
    const [offices, set_offices] = useState([])
        useEffect(() => {
            OfficesListFetch({}).then(res => {
                set_offices(res.data)
            })
        }, [])
    return (
        <div className="page_bvn2">
            <div className="ititle">Наши офисы</div>
            <div className="bvn2_cards">
                {offices?.map((val, i) => 
                    <div className="bvn2_card">
                        <div className="ititle">{val?.title}</div>
                        <div className="subtitile">
                            <div>Контакты:</div>
                            <div>тел: <a href={`tel:${val?.phone}`}>{val?.phone}</a></div>
                            <div>email: <a href={`mailto:${val?.email}`}>{val?.email}</a></div>
                        </div>
                        <div className="subsubtitle">
                            {val?.address}
                        </div>
                    </div>
                )}
            </div>

            <div className="office_map">
                <OfficeMap height="400px" />
            </div>
        </div>
    )
}