import React, {useState, useEffect} from "react";
import { AdminGetFaqCatsFetch, apiUrl, ArchiveFaqCatFetch, ArchiveNewFetch, CreateFaqCatFetch, CreateNewFetch, FaqItemAdminListFetch, FaqItemArchiveFetch, FaqItemCreateFetch, FaqItemUpdateFetch, GetAdminNews, UpdateFaqCatFetch, UpdateNewFetch } from "../../../connector/connectorV2";
import { Accordion, Button, Checkbox, Input, InputGroup, InputNumber, Modal, Pagination, Placeholder, SelectPicker, Stack } from "rsuite";

import AddOutlineIcon from '@rsuite/icons/AddOutline';
import EditIcon from '@rsuite/icons/Edit';

const yes_no_variables = [{label: "Да", value: true}, {label: "Нет", value: false}]

const FaqItemContentDriver = () => {
    const [cats, set_cats] = useState()
    const [all_faq_cats, set_all_faq_cats] = useState([])
    const [updated, set_updated] = useState(null)
    const [created, set_created] = useState(null)
    const [removed, set_removed] = useState(null)
    const [filter, set_filter] = useState({archived: false})

    const handleCloseRemove = () => {set_removed(null)}
    const handleOpenRemove = (item) => {set_removed(item)}

    const handleCloseUpdate = () => {set_updated(null)}
    const handleOpenUpdate = (item) => {set_updated(item)}

    const handleCloseCreate = () => {set_created(null)}
    const handleOpenCreate = () => {set_created({})}

    const ListAction = () => {
        FaqItemAdminListFetch(filter).then(res => {
            set_cats(res.data)
        })
    }
    const CreateAction = () => {
        FaqItemCreateFetch(created).then(res => {
            ListAction()
            handleCloseCreate()
        })
    }
    const UpdateAction = () => {
        FaqItemUpdateFetch(updated).then(res => {
            ListAction()
            handleCloseUpdate()
        })
    }
    const ArchiveAction = () => {
        FaqItemArchiveFetch({id: updated?.id, archived: !updated?.archived}).then(res => {
            ListAction()
            handleCloseUpdate()
        })
    }

    const CatsListAction = () => {
        AdminGetFaqCatsFetch({archived: false}).then(res => {
            set_all_faq_cats(res.data?.map(val => ({label: val.name, value: val.uid})))
        })
    }

    useEffect(() => {
        ListAction()
    }, [filter])
    useEffect(() => {
        CatsListAction()
    }, [])

    const tr_style = {gridTemplateColumns: "150px 150px 150px"}

    return (
        <>
        <div className="wrapper_content_1">
            <div className="my_simple_row">
                <div className="ptile">Записи</div>
                <Button color="blue" appearance="primary" onClick={handleOpenCreate}><AddOutlineIcon/></Button>
            </div>
            
            <div className="driver_wrapper">
                <div className="driver_item">
                    <div>Архив: </div>
                    <SelectPicker data={yes_no_variables} value={filter?.archived} onChange={(val) => {set_filter({...filter, ...{archived: val}})}} />
                </div>
            </div>
            <div className="das_table">
                <div className="das_tr das_header_tr" style={tr_style}>
                    <div>Название</div>
                    <div>Активно</div>
                    <div>Управление</div>
                </div>
                {cats?.map((val, i) => 
                    <div className="das_tr" style={tr_style} key={`cats_items_key_${i}`}>
                        <div>{val?.title}</div>
                        <div>{val?.archived ? <div className="round red"></div> : <div className="round green"></div>}</div>
                        <div className="my_simple_row"><EditIcon onClick={(e) => {handleOpenUpdate(val)}}/></div>
                    </div>
                )}
            </div>
        </div>
        {/* update modal */}
        <Modal open={updated != null} onClose={handleCloseUpdate}>
            <div className="driver_item">
                <div>Название</div>
                <Input value={updated?.title} onChange={(val) => {set_updated({...updated, ...{title: val}})}} />
            </div>
            <div className="driver_item">
                <div>UID</div>
                <Input value={updated?.uid} onChange={(val) => {set_updated({...updated, ...{uid: val}})}} />
            </div>
            <div className="driver_item">
                <div>Категория</div>
                <SelectPicker value={updated?.cat_uid} data={all_faq_cats} onChange={(val) => {set_updated({...updated, ...{cat_uid: val}})}} />
            </div>
            <div className="driver_item">
                <div>Текст</div>
                <Input as="textarea" rows={7} value={updated?.text} onChange={(val) => {set_updated({...updated, ...{text: val}})}} />
            </div>
            <div className="driver_btns">
                <Button onClick={UpdateAction}>Сохранить</Button>
                {!updated?.archived && <Button onClick={ArchiveAction}>Архивировать</Button>}
                {updated?.archived && <Button onClick={ArchiveAction}>Вернуть</Button>}
            </div>
        </Modal>
        {/* create modal */}
        <Modal open={created != null} onClose={handleCloseCreate}>
            <div className="driver_item">
                <div>Название</div>
                <Input value={created?.title} onChange={(val) => {set_created({...created, ...{title: val}})}} />
            </div>
            <div className="driver_item">
                <div>UID</div>
                <Input value={created?.uid} onChange={(val) => {set_created({...created, ...{uid: val}})}} />
            </div>
            <div className="driver_item">
                <div>Категория</div>
                <SelectPicker value={created?.cat_uid} data={all_faq_cats} onChange={(val) => {set_created({...created, ...{cat_uid: val}})}} />
            </div>
            <div className="driver_item">
                <div>Текст</div>
                <Input as="textarea" rows={7} value={created?.text} onChange={(val) => {set_created({...created, ...{text: val}})}} />
            </div>
            <div className="driver_btns"><Button onClick={CreateAction}>Сохранить</Button></div>
        </Modal>
        </>
    )
}

export default FaqItemContentDriver;