import React, {useState, useEffect} from "react";
import { AdminGetFaqCatsFetch, apiUrl, ArchiveFaqCatFetch, ArchiveNewFetch, CreateFaqCatFetch, CreateNewFetch, EventCelendarCreateFetch, EventCelendarListFetch, EventCelendarRemoveFetch, EventCelendarUpdateFetch, FaqItemAdminListFetch, FaqItemArchiveFetch, FaqItemCreateFetch, FaqItemUpdateFetch, GetAdminNews, UpdateFaqCatFetch, UpdateNewFetch } from "../../../connector/connectorV2";
import { Accordion, Button, Checkbox, DatePicker, Input, InputGroup, InputNumber, Modal, Pagination, Placeholder, SelectPicker, Stack } from "rsuite";
import TrashIcon from '@rsuite/icons/Trash';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import EditIcon from '@rsuite/icons/Edit';

const EventCelendarContentDriver = () => {
    const [cats, set_cats] = useState()
    const [updated, set_updated] = useState(null)
    const [created, set_created] = useState(null)
    const [removed, set_removed] = useState(null)
    const [filter, set_filter] = useState({archived: false})

    const handleCloseRemove = () => {set_removed(null)}
    const handleOpenRemove = (item) => {set_removed(item)}

    const handleCloseUpdate = () => {set_updated(null)}
    const handleOpenUpdate = (item) => {set_updated(item)}

    const handleCloseCreate = () => {set_created(null)}
    const handleOpenCreate = () => {set_created({})}

    const ListAction = () => {
        EventCelendarListFetch(filter).then(res => {
            set_cats(res.data)
        })
    }
    const CreateAction = () => {
        EventCelendarCreateFetch(created).then(res => {
            ListAction()
            handleCloseCreate()
        })
    }
    const UpdateAction = () => {
        EventCelendarUpdateFetch(updated).then(res => {
            ListAction()
            handleCloseUpdate()
        })
    }
    const RemoveAction = () => {
        EventCelendarRemoveFetch({id: removed?.id}).then(res => {
            ListAction()
            handleCloseRemove()
        })
    }

    useEffect(() => {
        ListAction()
    }, [filter])
    const tr_style = {gridTemplateColumns: "150px 150px 150px"}
    return (
        <>
        <div className="wrapper_content_1">
            <div className="my_simple_row">
                <div className="ptile">События</div>
                <Button color="blue" appearance="primary" onClick={handleOpenCreate}><AddOutlineIcon/></Button>
            </div>
            
            <div className="driver_wrapper">

            </div>
            <div className="das_table ">
                <div className="das_tr das_header_tr" style={tr_style}>
                    <div>Название</div>
                    <div>Активно</div>
                    <div>Управление</div>
                </div>
                {cats?.map((val, i) => 
                    <div className="das_tr" style={tr_style} key={`cats_items_key_${i}`}>
                        <div>{val?.title}</div>
                        <div>{val?.archived ? <div className="round red"></div> : <div className="round green"></div>}</div>
                        <div className="my_simple_row"><EditIcon onClick={(e) => {handleOpenUpdate(val)}}/><TrashIcon onClick={(e) => {handleOpenRemove(val)}} /> </div>
                    </div>
                )}
            </div>
        </div>
        {/* update modal */}
        <Modal open={updated != null} onClose={handleCloseUpdate}>
            <div className="driver_item">
                <div>Название</div>
                <Input value={updated?.title} onChange={(val) => {set_updated({...updated, ...{title: val}})}} />
            </div>
            <div className="driver_item">
                <div>Дата и время</div>
                <DatePicker format="dd/MM/yyyy HH:mm" value={updated?.event_datetime ? new Date(updated?.event_datetime) : null} onChange={(val) => {set_updated({...updated, ...{event_datetime: val}})}} />
            </div>
            <div className="driver_item">
                <div>Краткое описание</div>
                <Input value={updated?.description} onChange={(val) => {set_updated({...updated, ...{description: val}})}} />
            </div>
            <div className="driver_btns">
                <Button onClick={UpdateAction}>Сохранить</Button>
            </div>
        </Modal>
        {/* create modal */}
        <Modal open={created != null} onClose={handleCloseCreate}>
            <div className="driver_item">
                <div>Название</div>
                <Input value={created?.title} onChange={(val) => {set_created({...created, ...{title: val}})}} />
            </div>
            <div className="driver_item">
                <div>Дата и время</div>
                <DatePicker format="dd/MM/yyyy HH:mm" value={created?.event_datetime} onChange={(val) => {set_created({...created, ...{event_datetime: val}})}} />
            </div>
            <div className="driver_item">
                <div>Краткое описание</div>
                <Input value={created?.description} onChange={(val) => {set_created({...created, ...{description: val}})}} />
            </div>
            <div className="driver_btns"><Button onClick={CreateAction}>Сохранить</Button></div>
        </Modal>
        {/* remove modal */}
        <Modal open={removed != null} onClose={handleCloseRemove}>
            <div>Уврены, что хотите удалить</div>
            <div className="driver_btns"><Button onClick={RemoveAction}>Удалить</Button><Button onClick={handleCloseRemove}>Отмена</Button></div>
        </Modal>
        </>
    )
}

export default EventCelendarContentDriver;