import React, {useState, useEffect} from "react";
import NewsContentDriver from "./all/news";
import CategoriesContentDriver from "./all/categories";
import FaqItemContentDriver from "./all/faq_item";
import EventCelendarContentDriver from "./all/event_celendar";
import OfficiesContentDriver from "./all/officies";


export default function Content({}){
    return (
        <>
            <NewsContentDriver />
            <EventCelendarContentDriver />
            <FaqItemContentDriver />
            <div className="das_content_row">
                <CategoriesContentDriver />
                <OfficiesContentDriver />
            </div>
            
            
        </>
    )   
}