import React, { useEffect, useState } from "react";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { OfficesListFetch } from "../../connector/connectorV2";

export default function OfficeMap(props){
    const [offices, set_offices] = useState([])
    useEffect(() => {
        OfficesListFetch({}).then(res => {
            set_offices(res.data)
        })
    }, [])
    return (
        <YMaps
        >
            <div>
                <Map width={"100%"} height={props.height ? props.height : "200px"} defaultState={{ center: [55.75, 37.57], zoom: 11 }}>
                    {offices?.map(val => 
                        <Placemark modules={["geoObject.addon.balloon"]} geometry={[parseFloat(val.lat), parseFloat(val.lon)]} properties={{
                            balloonContentBody:
                            val.description,
                        }} />
                    )}
                </Map>
            </div>
        </YMaps>
    )
}