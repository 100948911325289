import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Input, InputNumber, MaskedInput, SelectPicker, Stack, Uploader } from "rsuite";
import logoSVG from './../../source/svg/logo.svg'
import { aplication_types } from "./const";
import { FileUploader } from "react-drag-drop-files";
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import { CreateApplicationFetch } from "../connector/connectorV2";
import CheckIcon from '@rsuite/icons/Check';
import HeaderV2 from "../atoms/header/headerV2";
import UploaderPM from "../atoms/UploaderPm/UploaderPm";
import ex1IMG from "./../../source/img/i1.jpg"
import ex2IMG from "./../../source/img/i2.jpg"
import ex3IMG from "./../../source/img/i3.jpg"

function PmTimeLine(props){
    const changeActive = (new_active) => {
        if (props.active == '1') return
        if (props.active == '2' && new_active != '1') return
        if (props.active == '3' && new_active != '2' && new_active != '1') return
        if (props.active == '4') return
        if (props.changeActive) {props.changeActive(new_active)}
    }
    return (
        <div className="pm_timeline_wrapper">
            <div onClick={(e) => {changeActive("1")}} className={`pm_timeline_item ${(props.active == '1' ? 'pm_timeline_active' : '')}`}>
                <div className="pm_timeline_round">1</div>
                <div className="pm_timeline_title">этап</div>
            </div>
            <div className="pm_timeline_hr"></div>
            <div onClick={(e) => {changeActive("2")}} className={`pm_timeline_item ${(props.active == '2' ? 'pm_timeline_active' : '')}`}>
                <div className="pm_timeline_round">2</div>
                <div className="pm_timeline_title">этап</div>
            </div>
            <div className="pm_timeline_hr"></div>
            <div onClick={(e) => {changeActive("3")}} className={`pm_timeline_item ${(props.active == '3' ? 'pm_timeline_active' : '')}`}>
                <div className="pm_timeline_round">3</div>
                <div className="pm_timeline_title">этап</div>
            </div>
            <div className="pm_timeline_hr"></div>
            <div className={`pm_timeline_item ${(props.active == '4' ? 'pm_timeline_active' : '')}`}>
                <div className="pm_timeline_round"><CheckIcon /></div>
                <div className="pm_timeline_title">готово</div>
            </div>
        </div>
    )
}


export default function ApplicationForm(props){
    const [step, setStep] = useState("1")
    const [aplication, setAplication] = useState({})
    const [wrong, setWrong] = useState([])
    const [politics_accept, set_politics_accept] = useState(true)
    const [aplication_hash, set_aplication_hash] = useState(null)
    const [aplication_id, set_aplication_id] = useState(null)

    const [lock, set_lock] = useState(false)

    const updateAplication = (new_val, key) => {
        let new_application = {...aplication}
        new_application[key] = new_val
        setAplication(new_application)
        setWrong([])
    }
    const stepToSecond = () => {
        let new_wrong = []
        if(aplication.applicant_login == null || aplication.applicant_login.trim() == ""){
            new_wrong.push("applicant_login")
        }
        if(aplication.applicant_name == null || aplication.applicant_name.trim() == ""){
            new_wrong.push("applicant_name")
        }
        if(aplication.applicant_email == null || aplication.applicant_email.trim() == ""){
            new_wrong.push("applicant_email")
        }
        if(aplication.applicant_phone == null || aplication.applicant_phone.trim() == ""){
            new_wrong.push("applicant_phone")
        }
        setWrong(new_wrong)
        if(new_wrong.length == 0){
            setStep("2")
        }
        
    }
    const UpdateType = (val) => {
        let reset_application = {...aplication}
        reset_application.type = val
        //reseting
        reset_application.order_create_date = null
        reset_application.order_send_date = null
        reset_application.order_img = null
        reset_application.box_image = null
        reset_application.invoice_image = null
        reset_application.screen_img = null
        reset_application.check_image = null
        reset_application.type_comment = null
        reset_application.user_comment = null
        reset_application.no_invoice = null
        setAplication(reset_application)
        setWrong([])
    }
    const stepToFird = () => {
        let new_wrong = []
        if(aplication.type == null){
            new_wrong.push("type")
        }
        if(aplication.type == "Повреждение (товара)"){
            if(aplication.box_image == null || aplication.box_image.length == 0){
                new_wrong.push("box_image")
            }
            if(aplication.type_comment == null || aplication.type_comment.length == 0){
                new_wrong.push("type_comment")
            }
            if(aplication.order_img == null || aplication.order_img.length == 0){
                new_wrong.push("order_img")
            }
            if((aplication.invoice_image == null || aplication.invoice_image.length == 0) && aplication.no_invoice != true){
                new_wrong.push("invoice_image")
            }
        }
        if(aplication.type == "Нехватка"){
            if(aplication.box_image == null || aplication.box_image.length == 0){
                new_wrong.push("box_image")
            }
            if(aplication.type_comment == null || aplication.type_comment.length == 0){
                new_wrong.push("type_comment")
            }
            if((aplication.invoice_image == null || aplication.invoice_image.length == 0) && aplication.no_invoice != true){
                new_wrong.push("invoice_image")
            }
        }
        if(aplication.type == "Перепутали товар"){
            if(aplication.box_image == null || aplication.box_image.length == 0){
                new_wrong.push("box_image")
            }
            if(aplication.type_comment == null || aplication.type_comment.length == 0){
                new_wrong.push("type_comment")
            }
            if((aplication.invoice_image == null || aplication.invoice_image.length == 0) && aplication.no_invoice != true){
                new_wrong.push("invoice_image")
            }
        }
        if(aplication.type == "Долгая доставка"){
            if(aplication.order_create_date == null){
                new_wrong.push("order_create_date")
            }
            if(aplication.order_send_date == null){
                new_wrong.push("order_send_date")
            }
            if((aplication.invoice_image == null || aplication.invoice_image.length == 0) && aplication.no_invoice != true){
                new_wrong.push("invoice_image")
            }
        }
        if(aplication.type == "Чужой заказ"){
            if(aplication.box_image == null || aplication.box_image.length == 0){
                new_wrong.push("box_image")
            }
            if(aplication.invoice_image == null || aplication.invoice_image.length == 0){
                new_wrong.push("invoice_image")
            }
        }
        if(aplication.type == "Срок годности"){
            if((aplication.invoice_image == null || aplication.invoice_image.length == 0) && aplication.no_invoice != true){
                new_wrong.push("invoice_image")
            }
            if(aplication.type_comment == null || aplication.type_comment.length == 0){
                new_wrong.push("type_comment")
            }
            if(aplication.order_img == null || aplication.order_img.length == 0){
                new_wrong.push("order_img")
            }

        }
        if(aplication.type == "Заводской брак"){
            if(aplication.box_image == null || aplication.box_image.length == 0){
                new_wrong.push("box_image")
            }
            if(aplication.box_image == null || aplication.box_image.length == 0){
                new_wrong.push("order_img")
            }
            if((aplication.invoice_image == null || aplication.invoice_image.length == 0) && aplication.no_invoice != true){
                new_wrong.push("invoice_image")
            }
        }
        if(aplication.type == "Другой вид претензии"){
            if(aplication.user_comment == null || aplication.user_comment.trim() == ""){
                new_wrong.push("user_comment")
            }
        }
        if(aplication.type == "Предложение по улучшению сервиса"){
            if(aplication.user_comment == null || aplication.user_comment.trim() == ""){
                new_wrong.push("user_comment")
            }
        }
        setWrong(new_wrong)
        if(new_wrong.length == 0){
            setStep("3")
        }
    }
    const SendApplication = () => {
        if(politics_accept != true){
            return
        }
        if(lock == true){
            return
        }
        set_lock(true)
        CreateApplicationFetch(aplication).then(res => {
            set_aplication_hash(res.data.application_hash)
            set_aplication_id(res.data.id)
            setStep('4')
            set_lock(false)
        })
    }
    const Reset = () => {
        setAplication({})
        setStep('1')
    }
    const numsValudatePartnerId = (val) => {
        if (val == ""){
            updateAplication(val, "applicant_login")
        } else if (/^\s*[\d]+([\d]+)?\s*$/.test(val)){
            updateAplication(val, "applicant_login")
        }
    }

    const fileTypes = ["JPG", "JPEG", "PNG"]
    const phoneMask = ['+', '7', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    const numberMask = [/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/]
    return(
        <div className="simple_page_wrapper">
            <HeaderV2 />
            <div className="application_title">Оформление новой заявки</div>
            <PmTimeLine active={step} changeActive={(val) => setStep(val)} />
            {step == "1" &&
                <div className="application_wrapper">
                    <div className="input_wrapper">
                        <div className="input_title">Введите пожалуйста партнерский номер на который был оформлен заказ:</div>
                        <Input className={wrong.includes("applicant_login") ? "wrong_input" : ""} value={aplication?.applicant_login} onChange={(val) =>  {numsValudatePartnerId(val)}} placeholder="Id партнера" />
                    </div>
                    <div className="input_wrapper">
                        <div className="input_title">ФИО:</div>
                        <Input className={wrong.includes("applicant_name") ? "wrong_input" : ""} onChange={(val) =>  updateAplication(val, "applicant_name")} placeholder="ФИО" />
                    </div>
                    <div className="input_wrapper">
                        <div className="input_title">E-mail:</div>
                        <Input className={wrong.includes("applicant_email") ? "wrong_input" : ""} onChange={(val) =>  updateAplication(val, "applicant_email")} placeholder="E-mail" />
                    </div>
                    <div className="input_wrapper">
                        <div className="input_title">Телефон:</div>
                        <MaskedInput mask={phoneMask} showMask={true} className={wrong.includes("applicant_phone") ? "wrong_input" : ""} onChange={(val) =>  updateAplication(val, "applicant_phone")} placeholder="Телефон" />
                    </div>
                    {wrong.length != 0 && <div className="aplication_wrong_text">Пожалуйста, заполните все поля</div>}
                    <Button color="blue" onClick={(e) => {stepToSecond()}} appearance="primary" className="pm_btn">Далее</Button>
                </div>
                }

            {step == "2" &&
                <div className="application_wrapper">
                    <div className="input_wrapper">
                        <div className="input_title">Уточните тип заявки:</div>
                        <Stack>
                            <Stack.Item><SelectPicker className={wrong.includes("type") ? "wrong_input" : ""} size="lg" searchable={false} data={aplication_types} value={aplication.type} onChange={(val) =>  {UpdateType(val)}} placeholder="Тип заявки" /></Stack.Item>
                        </Stack>
                        
                    </div>

                    {aplication?.type == "Повреждение (товара)" && 
                        <>
                            <div className="input_wrapper">
                                <div className="input_title">Какой товар поврежден (название, количество) (каждый с новой строки):</div>
                                <Input className={wrong.includes("type_comment") ? "wrong_input" : ""} as="textarea" rows={3}  onChange={(val) =>  updateAplication(val, "type_comment")} placeholder="" />
                            </div>
                            <div className="input_wrapper">
                                <div className="input_title">Общее фото коробки:</div>
                                <UploaderPM className={wrong.includes("box_image") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "box_image")}} fileTypes={fileTypes} />
                            </div>
                            <div className="input_wrapper">
                                <div className="input_title">Фото поврежденного товара:</div>
                                <UploaderPM className={wrong.includes("order_img") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "order_img")}} fileTypes={fileTypes} />
                            </div>
                            <div className="input_wrapper input_wrapper_row">
                                <Checkbox checked={aplication?.no_invoice} onChange={(val, boolVal) => updateAplication(boolVal, "no_invoice")} />
                                <div className="input_title">Нет накладной</div>
                            </div>
                            {!aplication?.no_invoice &&
                                <div className="input_wrapper">
                                    <div className="input_title">Фото накладной:</div>
                                    <UploaderPM className={wrong.includes("invoice_image") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "invoice_image")}} fileTypes={fileTypes} />
                                </div>
                            }
                        </>
                    }

                    {aplication?.type == "Нехватка" && 
                        <>
                            <div className="input_wrapper">
                                <div className="input_title">Какой товар отсутствует (название, количество) (каждый с новой строки):</div>
                                <Input className={wrong.includes("type_comment") ? "wrong_input" : ""} as="textarea" rows={3} onChange={(val) =>  updateAplication(val, "type_comment")} placeholder="" />
                            </div>
                            <div className="input_wrapper">
                                <div className="input_title">Общее фото посылки:</div>
                                <UploaderPM className={wrong.includes("box_image") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "box_image")}} fileTypes={fileTypes} />
                            </div>
                            <div className="input_wrapper input_wrapper_row">
                                <Checkbox checked={aplication?.no_invoice} onChange={(val, boolVal) => updateAplication(boolVal, "no_invoice")} />
                                <div className="input_title">Нет накладной</div>
                            </div>
                            {!aplication?.no_invoice &&
                                <div className="input_wrapper">
                                    <div className="input_title">Фото накладной:</div>
                                    <UploaderPM className={wrong.includes("invoice_image") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "invoice_image")}} fileTypes={fileTypes} />
                                </div>
                            }
                        </>
                    
                    }

                    {aplication?.type == "Перепутали товар" && 
                        <>
                            <div className="input_wrapper">
                                <div className="input_title">Опишите товар, который был ошибочно вложен, а какого не достает:</div>
                                <Input className={wrong.includes("type_comment") ? "wrong_input" : ""} as="textarea" rows={3} onChange={(val) =>  updateAplication(val, "type_comment")} placeholder="" />
                            </div>
                            <div className="input_wrapper">
                                <div className="input_title">Общее фото посылки:</div>
                                <UploaderPM className={wrong.includes("box_image") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "box_image")}} fileTypes={fileTypes} />
                            </div>
                            <div className="input_wrapper input_wrapper_row">
                                <Checkbox checked={aplication?.no_invoice} onChange={(val, boolVal) => updateAplication(boolVal, "no_invoice")} />
                                <div className="input_title">Нет накладной</div>
                            </div>
                            {!aplication?.no_invoice &&
                                <div className="input_wrapper">
                                    <div className="input_title">Фото накладной:</div>
                                    <UploaderPM className={wrong.includes("invoice_image") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "invoice_image")}} fileTypes={fileTypes} />
                                </div>
                            }
                        </>
                    }


                    {aplication?.type == "Долгая доставка" && 
                        <>
                            <div className="input_wrapper">
                                <div className="input_title">Дата оформления заказа:</div>
                                <DatePicker format="dd.MM.yyyy" className={wrong.includes("order_create_date") ? "wrong_input" : ""} onChange={(val) =>  updateAplication(val, "order_create_date")} placeholder="Дата офрмления заказа" />
                            </div>
                            <div className="input_wrapper">
                                <div className="input_title">Дата отправки заказа:</div>
                                <DatePicker format="dd.MM.yyyy" className={wrong.includes("order_send_date") ? "wrong_input" : ""} onChange={(val) =>  updateAplication(val, "order_send_date")} placeholder="Дата отправки заказа" />
                            </div>
                            <div className="input_wrapper">
                                <div className="input_title">Трек-номер (при наличии):</div>
                                <Input className={wrong.includes("type_comment") ? "wrong_input" : ""} onChange={(val) =>  updateAplication(val, "type_comment")} placeholder="" />
                            </div>
                            <div className="input_wrapper input_wrapper_row">
                                <Checkbox checked={aplication?.no_invoice} onChange={(val, boolVal) => updateAplication(boolVal, "no_invoice")} />
                                <div className="input_title">Нет накладной</div>
                            </div>
                            {!aplication?.no_invoice &&
                                <div className="input_wrapper">
                                    <div className="input_title">Фото накладной:</div>
                                    <UploaderPM  className={wrong.includes("invoice_image") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "invoice_image")}} fileTypes={fileTypes} />
                                </div>
                            }
                        </>
                    }

                    {aplication?.type == "Чужой заказ" && 
                        <>
                            <div className="aplication_subtitle">Пожалуйста, не используйте продукцию другого партнера, в ближайшее время мы отправим вам ваш заказ в полном объеме</div>
                            <div className="input_wrapper">
                                <div className="input_title">Общее фото посылки:</div>
                                <UploaderPM  className={wrong.includes("box_image") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "box_image")}} fileTypes={fileTypes} />
                            </div>
                            <div className="input_wrapper">
                                <div className="input_title">Фото накладной:</div>
                                <UploaderPM  className={wrong.includes("invoice_image") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "invoice_image")}} fileTypes={fileTypes} />
                            </div>
                        </>
                    }

                    {aplication?.type == "Срок годности" && 
                        <>
                            <div className="aplication_subtitle">Пожалуйста, оставьте фото с печатью даты срока годности на упаковке</div>
                            <div className="input_wrapper">
                                <div className="input_title">Пожалуйста, опишите товар, который был отправлен с плохим сроком годности (наименование, количество)</div>
                                <Input className={wrong.includes("type_comment") ? "wrong_input" : ""} as="textarea" rows={3} onChange={(val) =>  updateAplication(val, "type_comment")} placeholder="" />
                            </div>
                            <div className="input_wrapper">
                                <div className="input_title">Общее фото товара:</div>
                                <UploaderPM className={wrong.includes("order_img") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "order_img")}} fileTypes={fileTypes} />
                            </div>
                            <div className="input_wrapper input_wrapper_row">
                                <Checkbox checked={aplication?.no_invoice} onChange={(val, boolVal) => updateAplication(boolVal, "no_invoice")} />
                                <div className="input_title">Нет накладной</div>
                            </div>
                            {!aplication?.no_invoice &&
                                <div className="input_wrapper">
                                    <div className="input_title">Фото накладной:</div>
                                    <UploaderPM  className={wrong.includes("invoice_image") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "invoice_image")}} fileTypes={fileTypes} />
                                </div>
                            }
                            
                        </>
                    }

                    {aplication?.type == "Заводской брак" && 
                        <>
                            <div className="input_wrapper">
                                <div className="input_title">Общее фото товара:</div>
                                <UploaderPM className={wrong.includes("box_image") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "box_image")}} fileTypes={fileTypes} />
                            </div>
                            <div className="input_wrapper">
                                <div className="input_title">Фото зводской печати и срока годности:</div>
                                <div className="imag_application_example_wrapper">
                                    <img className="img_application_example" src={ex1IMG}  />
                                    <img className="img_application_example" src={ex2IMG}  />
                                    <img className="img_application_example" src={ex3IMG}  />
                                </div>
                                
                                <UploaderPM className={wrong.includes("order_img") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "order_img")}} fileTypes={fileTypes} />
                            </div>
                            <div className="input_wrapper input_wrapper_row">
                                <Checkbox checked={aplication?.no_invoice} onChange={(val, boolVal) => updateAplication(boolVal, "no_invoice")} />
                                <div className="input_title">Нет накладной</div>
                            </div>
                            {!aplication?.no_invoice &&
                                <div className="input_wrapper">
                                    <div className="input_title">Фото накладной:</div>
                                    <UploaderPM className={wrong.includes("invoice_image") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "invoice_image")}} fileTypes={fileTypes} />
                                </div>
                            }
                        </>
                    }

                    {aplication?.type == "Другой вид претензии" && 
                        <>
                            <div className="input_wrapper">
                                <div className="input_title">Фото:</div>
                                <UploaderPM className={wrong.includes("box_image") ? "wrong_input" : ""} UploadBaseFileAction={(file) => {updateAplication(file, "box_image")}} fileTypes={fileTypes} />
                            </div>
                        </>
                    }
                    {aplication?.type == "Предложение по улучшению сервиса" && 
                        <div>Мы гордимся тем, что вы являетесь нашим партнёром! Поделитесь своими мыслями по улучшению качества нашей работы. Ваши идеи - наше вдохновение. Спасибо за Вашу обратную связь!"</div>
                    }


                    <div className="input_wrapper">
                        <div className="input_title">Комментарий:</div>
                        <Input className={wrong.includes("user_comment") ? "wrong_input" : ""} as="textarea" rows={3} onChange={(val) =>  updateAplication(val, "user_comment")} placeholder="Комметарий" />
                    </div>

                    {wrong.length != 0 && <div className="aplication_wrong_text">Пожалуйста, заполните все поля</div>}
                    {!lock && <Button color="blue" onClick={(e) => {stepToFird()}} appearance="primary" className="pm_btn">Далее</Button>}
                </div>
                }
            {step == "3" && 
                <div className="application_wrapper">
                    <div className="input_wrapper input_wrapper_row">
                        <Checkbox className={(politics_accept != true ? "wrong_input" : "")} checked={politics_accept} onChange={(val, boolVal) => set_politics_accept(boolVal)} />
                        <div className="input_title">Я согласен с <a className="simple_a" target="_blank" href={`https://my-pmi.ru/data-politics`}>политикой обработки персональных данных</a></div>
                    </div>
                    {politics_accept != true && <div className="aplication_wrong_text">Пожалуйста, заполните все поля</div>}
                    <Button color="blue" onClick={(e) => {SendApplication()}} appearance="primary" className="pm_btn">Отправить заявку</Button>
                </div>
            }    
            {step == "4" && 
                <div className="application_wrapper">
                    <div className="application_title">Спасибо, что оставили заявку!</div>
                    <div>Наши специалисты примут меры в ближайшее время!</div>
                    <div>Ваша заявка принята в работу. В ближайшие десять рабочих дней мы обязательно разрешим Ваш вопрос. Статус заявки Вы можете отследить<a className="simple_a" target="_blank" href={`https://my-pmi.ru/application-tracking?track=${aplication_hash}&id=${aplication_id}`}>«здесь»</a></div>
                    <div className="bold">Пожалуйста, сохраните ссылку для отслеживания статуса обработки Вашей претензии, чтобы не потерять к данной функции доступ</div>
                    <Button color="blue" onClick={(e) => {Reset()}} appearance="primary" className="pm_btn">Оставить новую заявку</Button>
                </div>
            }     
        </div>
    )
}